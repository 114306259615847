/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import withStyles from '@mui/styles/withStyles';
// import ModalErrorHandler from "../ModalErrorHandler";
import Button from "@mui/material/Button";
import HeroReusableV2 from "./HeroReusableV2";
const HeroV10 = () => {
  const versionNumber = "012";
  const ColorButton = withStyles(() => ({
    root: {
      width: "100%",
      height: "35px",
    },
  }))(Button);
  return (
    <HeroReusableV2
      headerWithDesc={false}
      versionNumber={versionNumber}
      Animation={"StarChart"}
      ColorButton={ColorButton}
    />
  );
};
export default HeroV10;
